import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql, Link } from "gatsby";

const Pages = ({ data }) => {
  return (
    <Layout>
      <Seo />
      <div className="wrapper">
        <h1>Pages </h1>
        {data.pages.nodes.map(({ frontmatter, slug }) => {
          const { title, date } = frontmatter;

          return (
            <Link to={`/${slug}`}>
              <div className="bg-white shadow overflow-hidden p-8 my-4 rounded-md">
                <h3>{title}</h3>
              </div>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

export default Pages;

export const query = graphql`
  query {
    pages: allMdx(filter: { fileAbsolutePath: { regex: "/pages/" } }) {
      nodes {
        slug
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          relativeDate: date(fromNow: true)
          isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        }
      }
    }
  }
`;
